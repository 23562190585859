<template>
  <div class="register_box">
    <div class="black">
        <img @click="goblack" src="../../../../src/assets/image/black.png" alt="">
    </div>
    <div class="title">
        <div class="text1">注册账号</div>
        <div class="text2">快去注册您的账号～</div>
    </div>
    <!-- 注册框 -->
    <div class="login_pop">
        <div class="account_div">
            <input class="account" type="number" placeholder="请输入手机号" v-model="params.mobile">
        </div>
        <div class="code_div">
            <input class="codeNum" type="text" placeholder="请输入图片验证码" v-model="imgCodeVal">
            <span @click="getCodeImg" v-if="!imgCode">获取图片验证码</span>
            <img @click="getCodeImg" v-else :src="imgCode" alt="">
        </div>
        <div class="code_div">
            <input class="codeNum" type="text" placeholder="请输入短信验证码" v-model="params.smsCode">
            <span @click="getCodeSms" v-if="!isSendSms">{{smsText}}</span>
            <span v-else>{{time}}s后可重新发送</span>
        </div>
    </div>
    <div class="login_pop">
        <div class="password_div">
            <input class="password" type="password" placeholder="请输入密码" v-model="params.password">
        </div>
        <div class="password_div">
            <input class="password" type="password" placeholder="请确认密码" v-model="twicePassword">
        </div>
        <!-- <div class="password_div">
            <input class="password" type="text" placeholder="邀请码（非必填）">
        </div> -->
    </div>
    <div class="last_but">
        <div class="login_but" @click="doRegister">注册</div>
        <van-checkbox v-model="checked" checked-color="#CBAA9C" class="checkbox" icon-size="14">
            <tt>我已阅读并同意</tt><span><span @click.stop="openRule(4)">《用户协议》</span><span @click.stop="openRule(5)">《隐私保护指引》</span></span>
        </van-checkbox>
    </div>
    
    <van-popup class="ruletext" v-model="ruleShow">
        <div class="reletext" v-html="ruleText"></div>
        <div class="butann">
            <van-button type="default" @click="doReject">拒绝</van-button>
            <van-button type="warning" @click="doAgree">同意</van-button>
        </div>
    </van-popup>
    <bottomList></bottomList>
  </div>
</template>

<script>
import { doRegister, getRule, getImgCode, SendSms } from '@/api/user' // 注册 协议内容 图片验证码 短信验证码
export default {
  name: "register",

  data() {
    return {
        time: 60,
        timerhs: null, // 定时器
        checked: false,
        params: {
            mobile: "",
            smsCode: "",
            password: ""
        },
        imgCodeVal: '', // 图片验证码
        imgCode: '', // 图片验证码url
        imgCodeId: 0, // 图片验证码的id
        h:'',
        w: '',
        twicePassword: '', // 确认密码
        ruleText: '', // 协议内容
        ruleShow: false,
        rule4: false, // 是否查看视商协议
        rule5: false, // 是否查看隐私保护指引
        isdisabled: true,
        isSendSms: false, // 是否发送短信
        smsText: '获取验证码',
        ruleId: 0,
    };
  },

  mounted() {},

  methods: {
    // 返回上一页
    goblack() {
        // 返回上一页
       this.$router.go(-1);
    },
    // 注册登录
    doRegister() {
        var that = this
        if (!that.checked) {
            return that.$layer.quicktips('请阅读并同意协议')
        }
        const reg = /^1[3-9]{1}[0-9]{9}$/
        if (!reg.test(that.params.mobile)) {
            return that.$layer.quicktips('请输入正确的手机号')
        }
        if (!that.params.smsCode) {
            return that.$layer.quicktips('请输入短信验证码')
        }
        if (!that.params.password) {
            return that.$layer.quicktips('请输入密码')
        }
        if (that.params.password !== that.twicePassword) {
            return that.$layer.quicktips('二次输入密码不一样')
        }
        doRegister(that.params).then(res => {
            if (res.state == 200) {
                that.$layer.quicktips('注册成功')
                that.$router.push('./index')
            }
        })
    },
    // 查看协议
    openRule(id) {
        this.ruleShow = true
        this.ruleId = id
        // id == 4?this.rule4 = true:this.rule5 = true
        // if (this.rule4&&this.rule5){
        //     this.isdisabled = false
        // }
        getRule(id).then(res => {
            this.ruleText = res.data
        })
    },
    // 点击同意
    doAgree() {
        this.ruleId == 4?this.rule4 = true:this.rule5 = true
        if (this.rule4&&this.rule5){
            this.isdisabled = false
        }
        this.ruleShow = false
    },
    // 点击拒绝
    doReject() {
        this.ruleId == 4?this.rule4 = false:this.rule5 = false
        if (this.rule4&&this.rule5){
            this.isdisabled = false
        }
        this.ruleShow = false
    },
    // 获取图片验证码
    getCodeImg() {
        getImgCode().then(res => {
            this.imgCode = res.data.image
            this.imgCodeId=  res.data.id
            this.w = res.data.w
            this.h = res.data.h
        })
    },
    // 获取短信验证码
    getCodeSms() {
        var that = this
        const reg = /^1[3-9]{1}[0-9]{9}$/
        if (!reg.test(that.params.mobile)) {
            return that.$layer.quicktips('请输入正确的手机号')
        }
        var data = {
            mobile: that.params.mobile,
            behavior: 1, // 1注册 2忘记密码
            id: that.imgCodeId,
            code: that.imgCodeVal
        }
        SendSms(data).then(res => {
            if (res.state == 200) {
                that.$layer.quicktips('短信已发送请注意查收~')
                that.isSendSms = true
                that.timerhs = setInterval(() => {
                    that.time --
                    if (that.time == 1) {
                        clearInterval(that.timerhs)
                        that.smsText = '重新发送'
                        that.isSendSms = false
                        that.time = 60
                    }
                }, 1000)
            }
        })
    }
  },
};
</script>

<style lang="less" scoped>
.register_box{
    width: 100vw;
    height: 100vh;
    background: #fff;
}
.black{
    position: relative;
    text-align: center;
    padding: 14rem 0rem;
    background: #fff;
    img{
        position: absolute;
        left: 14rem;
        width: 17rem;
        height: 17rem;
    }
}
.title{
    padding: 30rem 32rem;
    .text1{
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 24rem;
        color: #000000;
    }
    .text2{
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14rem;
        color: #BEC2D1;
        margin-top: 2rem;
    }
}
.login_pop{
    padding: 30rem 32rem;
    input{
        border: none;
        font-family: DINPro, DINPro;
        font-weight: 500;
        font-size: 16rem;
        color: #333333;
    }
    .account{
        padding: 10rem 0rem;
    }
    .password{
        padding: 10rem 0rem;
    }
}
.account_div{
    border-bottom: 1rem solid #F5F6FB;
}
.password_div{
    border-bottom: 1rem solid #F5F6FB;
}
.code_div{
    padding: 20rem 0rem 10rem 0rem;
    border-bottom: 1rem solid #F5F6FB;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span{
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 16rem;
        color: #D1B4A8;
    }
}
.last_but{
    margin-top: 40rem;
    padding: 0rem 33rem;
    .login_but{
        height: 49rem;
        background: #E0BFB1;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 15rem;
        color: #333333;
        text-align: center;
        line-height: 49rem;
    }
    .dx_but{
        height: 49rem;
        background: #F2F3FC;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 15rem;
        color: #333333;
        text-align: center;
        line-height: 49rem;
        margin-top: 12rem;
    }
    .checkbox{
        margin-top: 12rem;
        tt{
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 11rem;
            color: #8D9399;
        }
        span{
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 11rem;
            color: #CBAA9C;
        }
    }
}
.van-popup{
    width: 75%;
    height: 60%;
    padding: 20rem;
    font-size: 14rem;
    line-height: 1.7;
}
.reletext{
    height: calc(100% - 30rem);
    overflow-y: auto;
}
.butann{
    text-align: center;
    // background: #fff;
    position: fixed;
    bottom: 0rem;
    left: 0rem;
    width: 100%;
    .van-button{
        margin-left: 10rem;
    }
}
</style>
